import "./styles.css";
import React from "react";
import $ from "jquery";

function Footer() {
  return (
    <div class="footer">
      <p>© Chuah Seng Yew 2023</p>
    </div>
  );
}

export default Footer;
